<template>
  <!-- Main content -->
  <section class="content">
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="用户user_id：">
          <el-input v-model="user_id" placeholder="请输入user_id"></el-input>
        </el-form-item>
        <el-form-item label="用户uid：">
          <el-input v-model="unionid" placeholder="请输入uid"></el-input>
        </el-form-item>
        <el-form-item label="渠道id：">
          <el-input v-model="relation_id" placeholder="请输入渠道id"></el-input>
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input v-model="phone" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="上级：">
          <el-input
            v-model="parent_unionid"
            placeholder="上级user_id"
          ></el-input>
        </el-form-item>
        <el-form-item label="支付宝：">
          <el-input
            v-model="zhifubao_account"
            placeholder="请输入支付宝"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="seach(1)">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table v-loading="loading" :data="memberList" border>
      <el-table-column fixed prop="id" label="用户user_id" width="200">
        <template slot-scope="scope">
          {{ scope.row.id }}({{ scope.row.user_key }})
        </template>
      </el-table-column>
      <el-table-column fixed prop="unionid" label="用户uid" width="250">
      </el-table-column>
      <el-table-column
        prop="relation_id"
        label="渠道id"
        width="120"
      ></el-table-column>
      <el-table-column label="注册时间" width="150">
        <template slot-scope="scope">
          {{ scope.row.regist_time | dateTime }}
        </template>
      </el-table-column>
      <el-table-column
        prop="balance"
        label="余额"
        sortable
        width="100"
      ></el-table-column>
      <el-table-column
        prop="score"
        label="积分"
        sortable
        width="100"
      ></el-table-column>
      <el-table-column
        prop="free_order_point"
        sortable
        label="免单点"
        width="100"
      ></el-table-column>
      <el-table-column prop="parent_id" label="上级" width="200">
        <template slot-scope="scope" v-if="scope.row.parent_id != 0">
          {{ scope.row.parent_id }} ( {{ scope.row.parent_key }} )
        </template>
      </el-table-column>
      <el-table-column
        prop="nick_name"
        label="昵称"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="zhifubao_account"
        label="支付宝"
        width="120"
      ></el-table-column>
      <el-table-column prop="zhifubao_name" label="真实姓名"></el-table-column>
      <el-table-column
        prop="phone"
        label="手机号"
        width="120"
      ></el-table-column>

      <el-table-column label="状态">
        <template slot-scope="scope">
          {{ scope.row.status | status }}
        </template>
      </el-table-column>
      <el-table-column prop="ban_reason" label="封停原因"></el-table-column>

      <el-table-column label="级别" width="100">
        <template slot-scope="scope">
          {{ scope.row.user_type | user_type }}
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="180">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="
              alterDialog(
                scope.row.id,
                scope.row.status,
                scope.row.user_type,
                scope.row.ban_reason
              )
            "
            >修改</el-button
          >
          <el-button
            type="warning"
            size="mini"
            v-if="scope.row.relation_id == ''"
            @click="accredit(scope.row.id)"
            >授权</el-button
          >
          <el-button
            type="warning"
            size="mini"
            v-if="scope.row.alipay_user_id"
            @click="unaccredit(scope.row.id)"
            >清除授权</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total, sizes,prev, pager, next,jumper"
      :total="total"
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>

    <el-dialog
      title="修改"
      :visible.sync="dialog"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="状态">
          <el-radio-group v-model="status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">封停</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="封停原因" v-if="status == 2">
          <el-input
            v-model="ban_reason"
            placeholder="请输入封停原因"
          ></el-input>
        </el-form-item>
        <el-form-item label="级别">
          <el-radio-group v-model="type">
            <el-radio :label="1">普通</el-radio>
            <el-radio :label="2">超级</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
      </div>
    </el-dialog>
  </section>
  <!-- /.content -->
</template>

<script>
let common = JSON.parse(window.localStorage.getItem("common"));
import * as api from "@/config/api";
export default {
  name: "member",
  components: {},
  data() {
    return {
      memberList: [],
      status_list: [
        {
          type: 1,
          name: "正常",
        },
        {
          type: 2,
          name: "封号",
        },
      ],
      user_type_list: [
        {
          type: 1,
          name: "普通",
        },
        {
          type: 2,
          name: "超级",
        },
      ],

      total: 0,
      page: 1,
      pagesize: 10,

      dialog: false,
      id: "",
      status: "",
      type: "",
      ban_reason: "",

      user_id: "",
      unionid: "",
      relation_id: "",
      parent_unionid: "",
      zhifubao_account: "",
      phone: "",

      loading: true,
    };
  },
  watch: {},
  filters: {
    user_type(val) {
      switch (Number(val)) {
        case 1:
          return "普通用户";
          break;
        default:
          return "超级用户";
          break;
      }
    },
    status(val) {
      switch (Number(val)) {
        case 1:
          return "正常";
          break;
        default:
          return "封号";
          break;
      }
    },
    dateTime(val) {
      let day = new Date(Number(val) * 1000);
      if (Number(val) === 0) {
        return "";
      }
      return (
        day.getFullYear() +
        "-" +
        `${
          day.getMonth() + 1 < 10
            ? "0" + (day.getMonth() + 1)
            : day.getMonth() + 1
        }` +
        "-" +
        `${day.getDate() < 10 ? "0" + day.getDate() : day.getDate()}` +
        " " +
        `${day.getHours() < 10 ? "0" + day.getHours() : day.getHours()}` +
        ":" +
        `${day.getMinutes() < 10 ? "0" + day.getMinutes() : day.getMinutes()}` +
        ":" +
        `${day.getSeconds() < 10 ? "0" + day.getSeconds() : day.getSeconds()}`
      );
    },
  },
  mounted() {
    this.getMember();
  },
  methods: {
    close() {
      this.show = false;
      this.getMember();
    },
    getMember(page, size) {
      this.loading = true;
      api.getMember(
        {
          user_id: this.user_id,
          unionid: this.unionid,
          relation_id: this.relation_id,
          parent_user_id: this.parent_unionid,
          zhifubao_account: this.zhifubao_account,
          phone: this.phone,
          page: page ? page : this.page,
          pagesize: size ? size : this.pagesize,
        },
        (res) => {
          page && (this.page = page)
          this.memberList = res.data.list;
          this.total = Number(res.data.count);
          this.loading = false;
        }
      );
    },
    sizeChange(val) {
      this.pagesize = val;
      this.getMember();
    },
    currentChange(val) {
      this.page = val;
      this.getMember();
    },
    seach(page, size) {
      this.getMember(page, size);
    },
    alterDialog(id, status, type, ban_reason) {
      this.id = id;
      this.status = Number(status);
      this.type = Number(type);
      this.ban_reason = ban_reason;
      this.dialog = true;
    },
    alter() {
      api.updateMember(
        {
          id: this.id,
          status: this.status,
          user_type: this.type,
          ban_reason: this.status == 2 ? this.ban_reason : "",
        },
        (res) => {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.getMember();
          this.dialog = false;
        }
      );
    },
    accredit(id) {
      api.accreditTb(
        {
          user_id: id,
        },
        (res) => {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.getMember();
          this.dialog = false;
        }
      );
    },
    unaccredit(id) {
      this.$confirm("确认清除支付宝授权？").then(() => {
        api.unaccreditZFB(
          {
            user_id: id,
          },
          (res) => {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getMember();
            this.dialog = false;
          }
        );
      });
    },
  },
};
</script>
<style scoped>
.tip {
  color: #ff5050;
}

.cell {
  text-align: center;
}

.btn-box {
  text-align: left;
  margin-bottom: 20px;
}

img {
  width: 150px;
}
</style>